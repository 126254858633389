import * as React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import { Box, Button, Grid, TextField, InputAdornment, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

import { CompanyCell, CompanyData } from './CompanyCell';
import { Modal } from '../../common/modal';
import { submitForm, FORM_TYPE } from '../../../utils/forms';
import { trackGAEvent } from '../../../utils/analytics';
import { useDebounce } from '../../../utils/debounce';
import { useStateWithSessionStorage } from './gridview.utils';
/**
 * Json with gatsby https://dimitr.im/using-json-with-gatsby
 */

const COMPANY_SEARCH_TIMEOUT = 1000;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    statusText: {
      marginTop: 5,
      fontSize: theme.typography.pxToRem(13)
    },
    loadMore: {
      textTransform: 'none',
      marginTop: 20
    },
    wrapper: {
      maxWidth: 1000,
      margin: "20px 25px 45px 25px",
      width: '100%'
    },
    gridContainer: {
      border: '1px solid #E1E1E1',
      padding: 20,
      borderRadius: 4
    },
    title: {
      marginBottom: 20,
      width: '100%',
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#E1E1E1',
        },
      }
    },
    formRoot: {
      display: 'flex',
      flexDirection: 'column',
      '& > *': {
         marginTop: theme.spacing(1),
         marginBottom: theme.spacing(1),
         width: '100%'
     },
   },
   formButton: {
     backgroundColor: theme.palette.primary.main,
     textTransform: 'lowercase',
     maxWidth: 100,
     alignSelf: 'flex-end',
     '&:hover': {
       backgroundColor: theme.palette.primary.main,
     },
     '&:active': {
       boxShadow: 'none',
       backgroundColor: theme.palette.primary.main,
     }
   },
  })
);

interface CompanyGridViewProps {
  pageSize: number;
}

const REQUEST_A_COMPANY: CompanyData = {
  "name": "request new company",
  "logo": "/logos/request-a-company.png",
  "type": "special"
};

const ERROR_KEY = 'unable to complete request!';
const SUCCESS_KEY = 'thank you for submitting!';
const GRID_PAGINATION_KEY = "gridPageSize";

export const CompanyGridView = ({ pageSize }: CompanyGridViewProps) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [name, setName] = React.useState('');
  const [companyUrl, setCompanyUrl] = React.useState('');
  const [statusMessage, setStatusMessage] = React.useState('');
  const [nameValidationWarning, setNameValidationWarning] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [paginationSize, setPaginationSize] = useStateWithSessionStorage(
    GRID_PAGINATION_KEY, '1'
  );

  useDebounce(() => {
    if (searchTerm) {
      trackGAEvent('overview.company_search', { q: searchTerm })
    }
  }, [searchTerm], COMPANY_SEARCH_TIMEOUT);

  const onNameChange = (e) => {
    setName(e.target.value);
    setNameValidationWarning(false);
  };

  const onCompanyUrlChange = (e) => setCompanyUrl(e.target.value);
  const parsedPagedSize = parseInt(paginationSize) || 1;

  const onSubmit = async () => {
    if (!name.trim()) {
      setNameValidationWarning(true);
      return;
    }

    try {
      setIsSubmitting(true);
      await submitForm({ name, companyUrl }, FORM_TYPE.NEW_COMPANY);
      setName('');
      setCompanyUrl('');
      setStatusMessage(SUCCESS_KEY);
    } catch(e) {
      setStatusMessage(ERROR_KEY);
    } finally {
      setIsSubmitting(false);
    }
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const onModalToggle = () => {
    setIsModalOpen(!isModalOpen);
    setStatusMessage('');
  };

  const onLoadMoreClick = () => setPaginationSize(`${parsedPagedSize + 1}`);

  const { allCompanies } = useStaticQuery(
    graphql`
      {
        allCompanies: allCompaniesJson(sort: {fields: name, order: ASC}) {
          edges {
            node {
              name
              logo
              fields {
                slug
              }
              ignore
            }
          }
        }
      }
    `
  );

  const companies: CompanyData[] = [];
  // we initialize this to 1 to account for the request company button
  let originalCompaniesLength = 1;
  for (let company of allCompanies.edges) {
    if (company.node.ignore) {
      continue;
    }

    if (companies.length >= (parsedPagedSize * pageSize)) {
      originalCompaniesLength = allCompanies.edges.length;
      break;
    }

    const { node: { name, logo, fields: { slug }}} = company;
    if (!searchTerm) {
        companies.push({ name, logo, slug });
        originalCompaniesLength++;
        continue;
    }

    if (name.toLowerCase().startsWith(searchTerm.toLowerCase())) {
      companies.push({ name, logo, slug });
      originalCompaniesLength++;
    }
  }
  companies.push({ ...REQUEST_A_COMPANY, onClick: onModalToggle });

  return (
    <>
      <Box display='flex' justifyContent='center'>
        <Box className={classes.wrapper}>
          <TextField
            className={classes.title}
            placeholder="search companies..."
            variant="outlined"
            value={searchTerm}
            onChange={onChange}
            InputProps={{
               startAdornment: (
                 <InputAdornment position="start">
                   <SearchIcon  style={{ fill: 'rgba(0, 0, 0, 0.5)' }} />
                 </InputAdornment>
               ),
             }}
          />
          <Box className={classes.gridContainer}>
            <Grid container spacing={3}>
              {companies.map((item, index) => <CompanyCell key={index} record={item} /> )}
            </Grid>
          </Box>
          {
            companies.length !== originalCompaniesLength &&
              <Button
                fullWidth
                className={classes.loadMore}
                onClick={onLoadMoreClick}
              >
                load more companies ...
              </Button>
          }
        </Box>
      </Box>
      <Modal
        title="request new company"
        isOpen={isModalOpen}
        onClose={onModalToggle}
        maxWidth={330}
        maxHeight={275}
        minWidth={100}
      >
        <Box flexDirection="column" display="flex" justifyContent="flex-end">
          <form className={classes.formRoot} noValidate autoComplete="off">
            <TextField
              label="company name"
              variant="outlined"
              error={nameValidationWarning}
              value={name}
              onChange={onNameChange}
            />
            <TextField
              label="company url (optional)"
              variant="outlined"
              value={companyUrl}
              onChange={onCompanyUrlChange}
            />
          </form>
          {statusMessage &&
            <Typography className={classes.statusText}>
            {statusMessage}
            </Typography>
          }
          <Button
            className={classes.formButton}
            variant="contained"
            disabled={isSubmitting}
            onClick={onSubmit}
          >
            <Typography style={{ fontWeight: 600 }}>
              submit
            </Typography>
          </Button>
        </Box>
      </Modal>
    </>
  );
};
