import * as React from 'react';

import { Layout } from '../common/layout';
import { Seo } from '../common/seo';
import { Hero } from './Hero';
import { CompanyGridView } from './gridview/CompanyGridView';
import * as AgentParser from 'ua-parser-js';

// there are instances where styles are not applied on initial
// page load in production. The solve is to use a css module as a backup
// https://github.com/gatsbyjs/gatsby/issues/21271#issuecomment-597613984. Turns
// out this is due to the nature of SSR apps. See
// https://github.com/gatsbyjs/gatsby/discussions/17914#discussioncomment-139432
// two pass rendering to solve styles not being applied. Alternatively we elect
// to check if the component has mounted before changing the props, this allows
// react to be able to reconcile without giving us styling issues.
const useHasMounted = () => {
  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, []);

  return hasMounted;
}

export const OverviewPage = () => {
  const isMounted = useHasMounted();
  let isMobile = false;
  if (isMounted) {
    const parser = new AgentParser();
    const { type } = parser.getDevice() || {};
    isMobile = type === 'mobile';
  }

  return (
    <Layout>
      <Seo title='Workforce Diversity Data' />
      <Hero />
      <CompanyGridView pageSize={isMobile ? 7 : 29} />
    </Layout>
  )
};
