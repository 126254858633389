import * as React from 'react';

export const useStateWithSessionStorage =
  (key: string, defaultValue: string = ''):
  [string, (value: string) => void] => {
  // session storage is only available in the browser
  const isSessionStorageAvailable = typeof sessionStorage !== 'undefined';
  const sessionValue = isSessionStorageAvailable && sessionStorage.getItem(key);
  const [value, setValue] = React.useState(sessionValue || defaultValue);

  React.useEffect(() => {
    if (isSessionStorageAvailable) {
        sessionStorage.setItem(key, value);
    }
  }, [isSessionStorageAvailable, value]);

  return [value, setValue];
};
