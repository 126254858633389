import * as React from 'react';
import { Link } from "gatsby"
import { Avatar, Box, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export interface CompanyData {
  logo: string;
  name: string;
  slug?: string;
  type?: 'special',
  onClick?: () => void;
};

interface CompanyCellProps {
  record: CompanyData
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    avatar: {
      width: 92,
      height: 92,
      padding: 18,
      border: '1px solid #E1E1E1',
      color: theme.palette.text.primary,
      background: 'transparent'
    },
    avatarImg: {
      marginBottom: 0,
      height: 'auto'
    },
    name: {
      marginTop: 10,
      fontSize: theme.typography.pxToRem(16)
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.primary,
      textAlign: 'center',
      borderRadius: 15,
      cursor: 'pointer',
      height: '100%',
      minHeight: 172,
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column'
    },
  }),
);

export const CompanyCell = ({ record }: CompanyCellProps) => {
  const { name, logo, slug, onClick } = record;
  const classes = useStyles();
  const element = (
    <Paper className={classes.paper} elevation={3}>
      <Box display='flex' justifyContent='center'>
        <Avatar classes={{
          root: classes.avatar,
          img: classes.avatarImg
        }} alt={`${name} logo`} src={logo} />
      </Box>
      <Typography className={classes.name}>
        {name}
      </Typography>
    </Paper>
  );

  return (
      <Grid item xs={6} sm={3} md={2}>
        {slug ?
          <Link to={slug}>{element}</Link> :
          <Box onClick={onClick}>{element}</Box>
        }
      </Grid>
  );
};
